import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import('@/views/LinksView.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/components/Admin.vue'),
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('@/components/Faq.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('@/views/AdminView.vue'),
  },
  {
    path: '/comments',
    name: 'Comments',
    component: () => import('@/components/Comments.vue'),
  },
  {
    path: '/outer',
    name: 'Outer',
    component: () => import('@/components/Outer.vue'),
  },
  {
    path: '/empower',
    name: 'Empower',
    component: () => import('@/views/EmpowerView.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/AboutView.vue'),
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import('@/views/ArticlesView.vue'),
  },
  {
    path: '/course',
    name: 'Course',
    component: () => import('@/views/CourseView.vue'),
  },
  {
    path: '/gift',
    name: 'Gift',
    component: () => import('@/components/Gift.vue'),
  },
  /* {
    path: '/flow',
    name: 'Flow',
    component: () => import('@/components/Flow.vue'),
  },
  {
    path: '/payment',
    name: 'Payment',
    component: () => import('@/components/Payment.vue'),
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import('@/components/Faq.vue'),
  }, */
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('@/components/Chat.vue'),
  },
  {
    path: '/work',
    name: 'MyWork',
    component: () => import('@/components/MyWork.vue'),
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('@/components/Quiz.vue'),
  },
  {
    path: '/sponsor',
    name: 'Sponsor',
    component: () => import('@/views/Sponsor.vue'),
  },
  {
    path: '/trace',
    name: 'Trace',
    component: () => import('@/views/TraceBack.vue'),
  },
  {
    path: '/trace_vue',
    name: 'TraceVue',
    component: () => import('@/views/TraceVue.vue'),
  },
  {
    path: '/change',
    name: 'Change',
    component: () => import('@/components/Change.vue'),
  },
  /* {
    path: '/gallary',
    name: 'Gallary',
    component: () => import('@/components/Gallary.vue'),
  }, */
] 

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
