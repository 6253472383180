// 引入 Firebase 必要的模組
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database' 
import { getAuth } from 'firebase/auth'

// Firebase 設定檔
// 包含專案的各項認證資訊
const fb = initializeApp({
    apiKey: 'AIzaSyCpGiPsWH6Ta8K_inFSUjUrdnB6J_F9PRM',      // API 金鑰
    authDomain: 'hack-84e1c.firebaseapp.com',                // 認證網域
    projectId: 'hack-84e1c',                                 // 專案 ID
    storageBucket: 'hack-84e1c.appspot.com',                // 儲存空間位置
    messagingSenderId: '892474981261',                       // 訊息發送者 ID
    appId: '1:892474981261:web:cb5224b361f5df46125bc4',     // 應用程式 ID
    measurementId: 'G-KB6KCXSXVY',                           // 測量 ID
  });

// 匯出資料庫實例，供其他檔案使用
export const db = getDatabase(fb)
// 匯出認證實例，供其他檔案使用
export const auth = getAuth(fb)
